// import { load } from "exifreader";

/**
 * A function to test whether a email is valid or not
 *
 * @param {String} email
 * @returns {Boolean}
 */
export function isValidEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

/**
 * A function to test whether a Phone number is valid or not
 *
 * @param {String} phoneNumber
 * @returns {Boolean}
 */
export function isValidPhoneNumber(phoneNumber, countryCode) {
  const maxLength = countryCode === "+62" ? 12 : 11;
  const re = new RegExp(`^[0-9]{6,${maxLength}}$`);
  return re.test(phoneNumber.toString());
}

/**
 * A function to test that username should only contain alphabets.
 *
 * @param {String} userName
 * @returns
 */
export function isValidUserName(userName) {
  var re = /^[a-zA-Z\s]*$/;
  return re.test(userName.toString());
}

export function convertToGB(totalImages, baseSize) {
  const sizeInMBs = Number(totalImages) * Number(baseSize);
  const sizeInGBs = sizeInMBs / 1024;

  return sizeInGBs.toFixed(2);
}

export function deepEqual(a, b) {
  if (typeof a == "object" && a != null && typeof b == "object" && b != null) {
    var count = [0, 0];
    // eslint-disable-next-line no-unused-vars
    for (let key in a) count[0]++;
    // eslint-disable-next-line no-unused-vars
    for (let key in b) count[1]++;
    if (count[0] - count[1] != 0) {
      return false;
    }
    for (let key in a) {
      if (!(key in b) || !deepEqual(a[key], b[key])) {
        return false;
      }
    }
    for (let key in b) {
      if (!(key in a) || !deepEqual(b[key], a[key])) {
        return false;
      }
    }
    return true;
  } else {
    return a === b;
  }
}

export const getDOURL = ({ url, original = false, thumb = false }) => {
  if (!url && typeof url !== "string") return "";

  let base =
    process.env.REACT_APP_DIGITAL_OCEAN_URL + url.split(".amazonaws.com/")[1];
  if (original) base = base.replace("/compress/", "/original/");
  else if (thumb) base = base.replace("/compress/", "/web-thumbnails/");

  return base;
};

export const getCFURL = ({
  url,
  original = false,
  thumb = false,
  pThumb = false,
}) => {
  if (!url && typeof url !== "string") return "";

  let base =
    process.env.REACT_APP_CLOUDFRONT_URL + url.split(".amazonaws.com/")[1];
  if (original) base = base.replace("/compress/", "/original/");
  else if (thumb) base = base.replace("/compress/", "/web-thumbnails/");
  else if (pThumb) base = base.replace("/compress/", "/thumbnail/");
  return base;
};

export const getWebThumbnailURL = (url) => {
  if (!url && typeof url !== "string") return "";
  return url.replace("/compress/", "/web-thumbnails/");
};

/**
 * A function to get the working URL of the image,
 * Checks first in Cloudfront, then in DO
 * Used in FolderImages & AnonymousFolderImages in the case Image height and width is not defined.
 * @param {String} url
 */
export async function getCorrectURL(url) {
  if (!url && typeof url !== "string") return "";
  let correctURL = getWebThumbnailURL(url);

  return new Promise((resolve) => {
    correctURL = getCFURL({ url: correctURL });

    const image = new Image();
    image.src = correctURL;

    image.onload = () => {
      resolve(correctURL);
    };

    image.onerror = () => {
      resolve(getDOURL({ url }));
    };
  });
}

export function delay(time = 300) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function getCompressedURL(webthumbnailURL) {
  if (!webthumbnailURL && typeof webthumbnailURL !== "string") return "";
  return webthumbnailURL.replace("/web-thumbnails/", "/compress/");
}

// convert bytes to human readable form eg:- 128323432 bytes to 12.83 MB
export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";

  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i >= sizes.length) i = sizes.length - 1;
  if (i > 1) {
    return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
  } else {
    return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
  }
}

export const getImageName = (url) => {
  if (!url) return "";
  let name = url.split("@").pop();
  name = decodeURI(name);
  return name;
};

export const getImageName2 = (url) => {
  if (!url) return "";
  let name = url.split("/").pop();
  name = decodeURI(name);
  return name;
};

export const getImageName3 = (url) => {
  if (!url) return "";
  let name = url.split("/").pop();
  name = decodeURI(name);
  name = name.split("_");
  name.shift();
  return name.join("_");
};

/**
 * 0 : image is not optimized
 * 1 : image is optimized using Kwikpic recommended settings
 * 2 : image is optimized using highres settings
 */
// eslint-disable-next-line no-unused-vars
export const isImageOptimized = async (image) => {
  return new Promise((resolve) => resolve(0));

  // uncomment code below to enable image optimization check
  // const data = await load(image);
  // const dsc = data?.CameraOwnerName?.description;
  // return dsc === "kopt" ? 1 : dsc === "highres" ? 2 : 0;
};

/**
 * @param {String} url
 * @param {String} authCode
 * @returns
 */
export const getNonAuthURL = (url, authCode) => {
  if (!authCode || authCode === "NP") return url;

  let splitURL = url.split("_" + authCode);
  const extension = splitURL.splice(-1, 1)[0];

  splitURL = splitURL.join("_" + authCode).concat(extension);

  return splitURL;
};
