import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  GET_ALL_IMAGES_OF_GROUP,
  GET_IMAGES_OF_GROUP,
  GET_MY_IMAGES,
  CHANGE_FAVORITE_STATUS,
  ADD_TO_CART,
  GET_PAID_PICS,
  REMOVE_FROM_CART,
  GET_DELETED_IMAGES,
} from "../../../../redux-store/sagas/saga-actions";
import GallaryModal from "./GallaryModal";
import Masony from "react-masonry-component";
import { getCorrectURL, getNonAuthURL } from "utils/helpers";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import useImpressionAnalytics from "hooks/useImpressionAnalytics";
import "../../../routes/styles/folderImages.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import GallaryEmpty from "../GallaryEmpty";
import {
  clearFolderImages,
  clearReevaluatePath,
  setFolderImageLoader,
} from "redux-store/slices/folderImages";
import UploadIconComp from "./UploadIconComp";
import Paginator from "./Paginator";
import { allImagesType } from "views/routes/GalleryRoute";
import Spinner from "views/components/loader/Spinner";
import ThreeDotAnimation from "views/components/loader/ThreeDotAnimation";
import { modifyGallaryRoute } from "utils/helpers/routes";
import { setImagesLoading } from "redux-store/slices/folderImages";
import Observable from "zen-observable";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import css from "./FolderImages.module.css";
import cx from "classnames";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
import CheckoutIcon from "./CheckoutIcon";
import useSize from "hooks/useSize";
import Selecto from "react-selecto";
import { toast } from "react-toastify";
import ImageWithOptions from "./ImageWithOptions";
import usePreventUnsavedNavigation from "hooks/usePreventUnsavedNavigation";

const MultiSelectActions = React.lazy(() => import("./MultiSelectActions"));

export const masonryOptions = {
  fitWidth: false,
  gutter: 0,
  itemSelector: ".photo-item",
  transitionDuration: 0,
};

export const IMAGES_PER_PAGE = 300;
/**
 * time in minutes after which downOQ will be ignored
 */
export const DOWNOQ_OFFSET_MAX = 10;
const LOAD_IMAGES_PER_TIME = 10;

const FolderImages = ({ folderId, handleShow, hasUploadPermission }) => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const [imageView, setImageView] = useState({ show: false, selectedID: null });
  const [updatedImages, setUpdatedImages] = useState([]);
  const [removeLoader, setRemoveLoader] = useState({});
  const [loading, setLoading] = useState(true);
  const pageNumberFromURL = Number(params.get("page"));

  const pageRef = useRef(1);
  const [imagesToBeRendered, setImagesToBeRendered] = useState([]);
  const imagesRefs = useRef({});
  const { width: windowWidth } = useSize();

  const dispatch = useDispatch();

  const { _id: currentUserId } = getLocalStorageUser();

  const groupId = params.get("groupId");

  const { cartItems, cartLoader } = useSelector((state) => state.cart);

  const {
    allPics: images = [],
    totalCount,
    imagesLoading,
    fullScreenImages,
    folderImageLoader,
    reevaluatePath,
  } = useSelector((state) => state.folderImages);

  const {
    groupDetails: { isForProductSale },
    leaveParticipantSettings: { isAdmin },
    designSettings,
  } = useSelector((state) => state.settings);

  // using these loaders because GET_FOLDERS is called on transfering
  // images in multi-select
  const { loader, initialLoader } = useSelector((state) => state.folders);

  const { padding, photoSize } = designSettings || {};

  const totalPages = Math.ceil(totalCount / IMAGES_PER_PAGE);

  const handlePageChange = useCallback(
    (goToPage, totalPages) => {
      if (
        !(goToPage <= 0) &&
        !(goToPage > totalPages) &&
        goToPage !== pageNumberFromURL
      ) {
        window.scroll(0, 0);
        setRemoveLoader({});
        navigate(modifyGallaryRoute({ pageNo: goToPage, totalPages }, params));
        setLoading(true);
      }
    },
    [navigate, pageNumberFromURL, params]
  );

  const { removedImagesRef } = useIntersectionObserver({
    updatedImages,
    folderId,
  });

  useImpressionAnalytics({
    updatedImages,
    folderId,
  });

  const getData = useCallback(
    (currentPage) => {
      if (!folderId) return;
      if (folderId === "purchased") {
        dispatch({
          type: GET_PAID_PICS,
          payload: {
            groupId,
            page: currentPage,
            limit: IMAGES_PER_PAGE,
          },
        });
      } else if (folderId === "my-photos") {
        dispatch({
          type: GET_MY_IMAGES,
          payload: {
            groupID: groupId,
            page: currentPage,
            limit: IMAGES_PER_PAGE,
          },
        });
      } else if (folderId === "favorites") {
        dispatch({
          type: GET_MY_IMAGES,
          payload: {
            groupID: groupId,
            page: currentPage,
            limit: IMAGES_PER_PAGE,
            isFavorite: true,
            folderId: "favorites",
          },
        });
      } else if (folderId === "all-photos") {
        dispatch({
          type: GET_ALL_IMAGES_OF_GROUP,
          groupID: groupId,
          page: currentPage,
          limit: IMAGES_PER_PAGE,
          imageType: allImagesType.DATE,
        });
      } else if (folderId === "deleted") {
        dispatch({
          type: GET_DELETED_IMAGES,
          payload: {
            groupId,
            page: currentPage,
            limit: IMAGES_PER_PAGE,
          },
        });
      } else {
        dispatch({
          type: GET_IMAGES_OF_GROUP,
          folderId,
          page: currentPage,
          limit: IMAGES_PER_PAGE,
        });
      }
    },
    [dispatch, folderId, groupId]
  );

  useEffect(() => {
    if (!isNaN(pageNumberFromURL) && pageNumberFromURL > 0) {
      getData(pageNumberFromURL);
    }
  }, [getData, pageNumberFromURL]);

  // this should not run when changing folders
  useEffect(() => {
    if (reevaluatePath) {
      dispatch(setFolderImageLoader(true));
      new Promise((resolve) => {
        setTimeout(() => {
          getData(pageNumberFromURL);
          dispatch(clearReevaluatePath());
          dispatch(setFolderImageLoader(false));
          resolve();
        }, 1000);
      });
    }
  }, [dispatch, getData, pageNumberFromURL, reevaluatePath]);

  const init = () => {
    dispatch(clearFolderImages());
    pageRef.current = 1;
    setImagesToBeRendered([]);
    setUpdatedImages([]);
  };

  useEffect(() => {
    // getData();

    // temporary fix for the issue where the thumbs are not loading
    const timeout = setTimeout(() => setLoading(false), 15000);

    return () => {
      init();
      clearTimeout(timeout);
    };
  }, [folderId]);

  /**
   * This is being used when the images in api response don't have height and width defined.
   * Test: https://reactwebsite2-e57w0n2fm-harshkwikpic.vercel.app/auth/login?uCode=GIYOSU&adminToken=GIYOSUn9sL5PZ5
   */
  useEffect(() => {
    let _imagesToBeRendered = [...(imagesToBeRendered || [])];

    if (_imagesToBeRendered?.length === 0) return;
    const observable = new Observable(async (observer) => {
      if (_imagesToBeRendered?.length === 0) {
        observer.complete();
      }
      let newImages = _imagesToBeRendered.splice(0, LOAD_IMAGES_PER_TIME);
      let imagesCount = 0;

      for (const image of newImages) {
        if (!image) {
          newImages = newImages.filter((img) => img);

          if (imagesCount === newImages.length) {
            observer.next({
              renderedImages: newImages,
              leftImages: _imagesToBeRendered,
            });
          }

          continue;
        }

        const img = new Image();
        const URL = await getCorrectURL(image.url);
        img.src = URL;

        img.onload = function () {
          imagesCount += 1;
          newImages = newImages.map((_img) => {
            if (_img._id === image._id) {
              return {
                ..._img,
                webThumbWidth: img.width,
                webThumbHeight: img.height,
              };
            }
            return _img;
          });

          if (imagesCount === newImages.length) {
            observer.next({
              renderedImages: newImages,
              leftImages: _imagesToBeRendered,
            });
          }
        };

        img.onerror = () => {
          newImages = newImages.filter((_img) => _img._id !== image._id);
          if (imagesCount === newImages.length) {
            observer.next({
              renderedImages: newImages,
              leftImages: _imagesToBeRendered,
            });
          }
        };
      }
    });

    const subscription = observable.subscribe({
      next: ({ renderedImages, leftImages }) => {
        setUpdatedImages((prev) => [...(prev || []), ...renderedImages]);
        setImagesToBeRendered(leftImages);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [imagesToBeRendered]);

  useEffect(() => {
    const imagesWithNoWidthHeight = [];
    const imagesWithWidthHeight = images?.[folderId]?.filter((image) => {
      if (image?.webThumbHeight && image?.webThumbWidth) {
        return true;
      } else if (!image) {
        // filter out undefined images
        // not mandatorily required, but will prevent unnecessary call of useEffect above
      } else {
        imagesWithNoWidthHeight.push(image);
      }

      return false;
    });

    setUpdatedImages(imagesWithWidthHeight);
    setImagesToBeRendered(imagesWithNoWidthHeight);

    return () => {
      setUpdatedImages([]);
    };
  }, [images?.[folderId]]);

  const closeImageView = useCallback(
    (shouldRefetch = false) => {
      if (shouldRefetch) {
        dispatch(setImagesLoading(true));
        getData(pageNumberFromURL);
      }
      params.delete("imageId");
      setParams(params);

      setImageView({
        show: false,
        selectedID: null,
      });
    },
    [dispatch, getData, pageNumberFromURL, params, setParams]
  );

  const toggleFavorite = useCallback(
    (url, index, authCode, fromModal = false, isFavorite) => {
      const actualUrl = getNonAuthURL(url, authCode);

      dispatch({
        type: CHANGE_FAVORITE_STATUS,
        payload: {
          _id: groupId,
          url: actualUrl,
          index,
          page: +params.get("page"),
          fromModal,
          folderId,
          isFavorite,
        },
      });
    },
    [dispatch, groupId, params, folderId]
  );

  const addToCart = useCallback(
    (imgId) => {
      dispatch({
        type: ADD_TO_CART,
        payload: {
          groupId,
          productType: "DigitalPhoto",
          productIds: [imgId],
        },
      });
    },
    [dispatch, groupId]
  );

  const removeFromCart = useCallback(
    (imgId) => {
      dispatch({
        type: REMOVE_FROM_CART,
        payload: {
          groupId,
          cartItemIds: [imgId],
        },
      });
    },
    [dispatch, groupId]
  );

  const selectoRef = useRef();
  /**
   * @typedef {Map<string, [URL: string, authCode: string, number: string, number: string]>} SelectedImagesMap
   * @type {[SelectedImagesMap, React.Dispatch<React.SetStateAction<SelectedImagesMap>>]}
   */
  const [selectedImagesMap, setSelectedImagesMap] = useState(new Map());

  useEffect(() => {
    const onScroll = () => {
      selectoRef.current.checkScroll();
    };

    if (selectedImagesMap.size > 0 && windowWidth > 768) {
      window.addEventListener("scroll", onScroll);
    }

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [selectedImagesMap.size, windowWidth]);

  const toggleSelect = useCallback((id, url, authCode, size, index) => {
    setSelectedImagesMap((prev) => {
      if (prev.size === 0)
        toast.info("Gallery is now in Selection Mode.", {
          containerId: "infos",
        });

      const newSelectedImagesMap = new Map(prev);

      if (newSelectedImagesMap.has(id)) {
        newSelectedImagesMap.delete(id);
      } else {
        newSelectedImagesMap.set(id, [url, authCode, size, index]);
      }

      if (newSelectedImagesMap.size === 0)
        toast.info("Selection Mode disabled.", { containerId: "infos" });

      return newSelectedImagesMap;
    });
  }, []);

  usePreventUnsavedNavigation({
    block: selectedImagesMap.size > 0,
    refresh: false,
  });

  useEffect(() => {
    // clear selected images map when folder or page changes
    if (selectedImagesMap.size > 0) {
      setSelectedImagesMap(new Map());
    }
  }, [folderId, pageNumberFromURL]);

  return (
    <>
      {windowWidth > 768 && selectedImagesMap.size > 0 && (
        <Selecto
          ref={selectoRef}
          dragContainer=".gallaryPage"
          selectableTargets={[".photo-item"]}
          selectByClick={false}
          selectFromInside={true}
          continueSelect={true}
          continueSelectWithoutDeselect={true}
          hitRate={5}
          onDragStart={(e) => {
            if (e.inputEvent.target.nodeName === "BUTTON") {
              return false;
            }
            return true;
          }}
          onSelect={(e) => {
            const newSelectedImagesMap = new Map(selectedImagesMap);

            e.added.forEach((el) => {
              newSelectedImagesMap.set(el.attributes["data-img-id"].value, [
                el.attributes["data-img-url"].value,
                el.attributes["data-img-authcode"]?.value,
                el.attributes["data-img-size"]?.value,
                el.attributes["data-img-index"]?.value,
              ]);
            });

            e.removed.forEach((el) => {
              newSelectedImagesMap.delete(el.attributes["data-img-id"].value);
            });

            setSelectedImagesMap(newSelectedImagesMap);
          }}
          scrollOptions={{
            container: document.body,
            getScrollPosition: () => [
              document.documentElement.scrollLeft,
              document.documentElement.scrollTop,
            ],
            throttleTime: 30,
            threshold: 0,
          }}
          onScroll={(e) => {
            document.documentElement.scrollBy(
              e.direction[0] * 10,
              e.direction[1] * 10
            );
          }}
        />
      )}

      <div className="no-overflow-x position-relative">
        <Masony
          options={masonryOptions}
          disableImagesLoaded={true}
          className="gallery-masonry-container"
        >
          {!imagesLoading &&
            updatedImages?.map((image, index) => {
              return (
                <ImageWithOptions
                  key={image._id || index}
                  image={image}
                  padding={padding}
                  photoSize={photoSize}
                  windowWidth={windowWidth}
                  folderId={folderId}
                  isSelected={
                    selectedImagesMap.has(image._id) ||
                    selectedImagesMap.has(image.imageId)
                  }
                  isForProductSale={isForProductSale}
                  currentUserId={currentUserId}
                  loading={loading}
                  setLoading={setLoading}
                  setRemoveLoader={setRemoveLoader}
                  imagesRefs={imagesRefs}
                  removedImagesRef={removedImagesRef}
                  setImageView={setImageView}
                  removeLoader={removeLoader}
                  index={index}
                  removeFromCart={removeFromCart}
                  addToCart={addToCart}
                  toggleFavorite={toggleFavorite}
                  isAdmin={isAdmin}
                  selectMode={selectedImagesMap.size > 0}
                  toggleSelect={toggleSelect}
                />
              );
            })}
        </Masony>

        {imagesToBeRendered?.length > 0 ? (
          <ThreeDotAnimation
            customizeClass={"d-flex justify-content-center vw-100 mb-3 pt-5"}
          />
        ) : (
          images?.[folderId]?.length !== 0 && (
            <div className="d-flex justify-content-center gallary-footer">
              {pageNumberFromURL > 1 && !loading && (
                <div
                  onClick={() =>
                    handlePageChange(pageNumberFromURL - 1, totalPages)
                  }
                  className="prev-wrapper-footer font-bold font-20 cursor-pointer"
                >
                  <img
                    className="prev-btn-footer"
                    src="/assets/images/icons/back-arrow.png"
                    alt="prev-arrow"
                  />
                  Previous
                </div>
              )}
              {pageNumberFromURL > 1 &&
                pageNumberFromURL < totalPages &&
                !loading && <div className="separator-footer" />}
              {pageNumberFromURL < totalPages && !loading && (
                <div
                  onClick={() =>
                    handlePageChange(pageNumberFromURL + 1, totalPages)
                  }
                  className="next-wrapper-footer font-bold font-20 cursor-pointer"
                >
                  Next
                  <img
                    className="next-btn-footer rot-180"
                    src="/assets/images/icons/back-arrow.png"
                    alt="next-arrow"
                  />
                </div>
              )}
            </div>
          )
        )}

        <Paginator
          currentPage={Number(pageNumberFromURL)}
          totalCount={totalCount}
          perPageCount={IMAGES_PER_PAGE}
          onPageChange={(goToPage, totalPages) => {
            handlePageChange(goToPage, totalPages);
          }}
        />

        {Array.isArray(images?.[folderId]) &&
          images?.[folderId]?.length === 0 && (
            <div className="d-flex justify-content-center vw-100 p-20">
              <GallaryEmpty
                folderId={folderId}
                setLoading={setLoading}
                hasUploadPermission={hasUploadPermission}
                handleShow={handleShow}
              />
            </div>
          )}

        {(images?.[folderId]?.length > 0 || folderId === "my-photos") && (
          <UploadIconComp
            hasUploadPermission={hasUploadPermission}
            handleShow={handleShow}
            className={cx({
              [css.psUpload]: isForProductSale,
            })}
          />
        )}

        {isForProductSale && (
          <CheckoutIcon totalItems={cartItems.length} groupId={groupId} />
        )}

        {params.has("imageId") &&
          fullScreenImages?.length > 0 &&
          selectedImagesMap.size === 0 && (
            <GallaryModal
              selectedID={imageView.selectedID}
              folderId={folderId}
              closeImageView={closeImageView}
              imagesRefs={imagesRefs}
              fetchData={getData}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
            />
          )}

        <Spinner
          loading={
            loading ||
            imagesLoading ||
            cartLoader ||
            folderImageLoader ||
            loader ||
            initialLoader
          }
        />

        <React.Suspense fallback={<SuspenseLoader />}>
          {selectedImagesMap.size > 0 && (
            <MultiSelectActions
              selectedItems={selectedImagesMap}
              setSelectedItems={setSelectedImagesMap}
            />
          )}
        </React.Suspense>
      </div>
    </>
  );
};

export default FolderImages;
