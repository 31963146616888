import { toast } from "react-toastify";
import { getCompressedURL, getCFURL } from "utils/helpers";
import { AXIOS } from "utils/setup/axios";
// import { allImagesType } from "views/routes/GallaryRoute";
import { IMAGE_SIZE_DIFFERENCE } from "./components/FolderDownloads";
import { groupHasImagesSelected } from "utils/helpers/localstorage";
import axios from "axios";
import { detect } from "detect-browser";
import ENV from "utils/helpers/env";
import { getDeviceType } from "hooks/useDetectUserAgent";
import webShare from "utils/helpers/web-share";
import { FILENAME_SANITIZE, FOLDERNAME_SANITIZE } from "constants/regexlib";

export async function getFolderImages(id, groupId) {
  if (id === "All") {
    const response = await AXIOS.get(
      "/api/app/pic/all-pics-download/" + groupId
    );

    const { data } = response?.data || {};
    return data?.pics || [];
  } else if (id === "my-photos" || id === "favorites") {
    const response = await AXIOS.get(`/api/app/pic/list/${groupId}`, {
      params: {
        page: 1,
        limit: 100000, // need to find a fix for it
        ...(id === "favorites" ? { isFavorite: true } : {}),
      },
    });
    return response?.data?.data?.pics || [];
  } else if (id === "purchased") {
    const response = await AXIOS.get(`/api/app/pic/paid-pics/${groupId}`);

    return response?.data?.data?.paidPics || [];
  } else if (id === "selected-images") {
    return groupHasImagesSelected(groupId);
  } else {
    const response = await AXIOS.get("/api/app/groupFolder/folderPics/" + id, {
      params: {
        page: 1,
        limit: 100000, // need to find a fix for it
      },
    });

    const { data } = response?.data || {};
    return data?.allPics || [];
    // if (data) {
    //   const images = [];
    //   data?.pics?.forEach((pic) => images.push(...(pic?.images || [])));
    //   return images;
    // }
    // return [];
  }
}

export async function* getFilesRecursively(entry) {
  if (entry.kind === "file") {
    const file = await entry.getFile();
    if (file !== null) {
      yield file;
    }
  } else if (entry.kind === "directory") {
    for await (const handle of entry.values()) {
      yield* getFilesRecursively(handle);
    }
  }
}

/**
 * Downloads a single image with error check, used with the function downloadImages
 * @param {Object} pic
 * @param {String} directory
 */
export const downloadImage = async (pic, directory) => {
  const { downloadURL, url, folderPrefix } = pic;
  let result = true;
  const { fileName } = createFilename(url);

  try {
    let _directory = directory;
    if (folderPrefix) {
      try {
        _directory = await _directory.getDirectoryHandle(folderPrefix.trim(), {
          create: true,
        });
      } catch (_) {
        _directory = await _directory.getDirectoryHandle(
          sanitizePath(folderPrefix),
          {
            create: true,
          }
        );
      }
    }
    await _directory
      .getFileHandle(fileName, { create: true })
      .then(async (data) => {
        const writable = await data.createWritable();
        let response;
        try {
          response = await fetch(downloadURL);
          if (!response.ok) {
            response = await fetch(url);
          }
          await response.body.pipeTo(writable);
        } catch (err) {
          result = false;

          /*
          Handling for CORS error
          In case of CORS error, response comes as undefined
          Need to check if there's any other case where response is undefined
          */
          if (!response) {
            response = await fetch(url);
            await response.body.pipeTo(writable);
            result = true;
          }
        }

        if (response?.status !== 200) {
          result = false;
        }
      });
    return result;
  } catch (_) {
    return false;
  }
};

/**
 * CHecks results from API and Disk, return an array of non-existing images and count of existing images
 * @param {Array} images Image array from API response
 * @param {Array} imagesNameAndSizes Image array from disk
 * @param {Boolean} originalQuality Whether to download original quality images
 */
export function getToBeDownloadedImages(
  images, // data from api
  imagesNameAndSizes, // data from disk
  originalQuality
) {
  let successResponseCount = 0;
  let toBeDownloadedImages = [];

  images.forEach(async (pic) => {
    const { url, size: compressedSize } = pic;
    const { fileName, fileSize } = createFilename(url);

    const duplicateImage = imagesNameAndSizes.find((file) => {
      return (
        file.name.toLowerCase() === fileName.toLowerCase() &&
        Math.abs(
          Number(originalQuality ? fileSize : compressedSize) -
            Number(file.size)
        ) <= IMAGE_SIZE_DIFFERENCE
      );
    });

    if (duplicateImage) {
      successResponseCount = successResponseCount + 1;
      return;
    }

    // toBeDownloadedImages.push([digitalOceanURL, url]);
    toBeDownloadedImages.push(pic);
  });

  return {
    successResponseCount,
    toBeDownloadedImages,
  };
}

/**
 * Downloads an array of images in a directory, used in bulk downloads
 * @param {Array} images
 * @param {String} directory
 * @param {Number} incrementProgress
 */
export function downloadImages(
  images,
  directory,
  incrementProgress = () => {}
) {
  return new Promise((resolve) => {
    let imagesCount = images.length;
    let imagesDownloaded = 0;
    images.forEach(async (pic) => {
      try {
        const result = await downloadImage(pic, directory);

        imagesDownloaded = imagesDownloaded + 1;

        if (result) {
          incrementProgress();
        } else {
          incrementProgress(undefined, false, pic);
        }
        if (imagesDownloaded === imagesCount) {
          resolve();
        }
      } catch (_) {
        imagesDownloaded = imagesDownloaded + 1;
        incrementProgress(undefined, false, pic?.url);
        if (imagesDownloaded === imagesCount) {
          resolve();
        }
      }
    });
  });
}

/**
 * @param {String} url
 * @returns {Object} filename and fileSize
 */
export function createFilename(url) {
  if (!url) return { fileName: "", fileSize: 0 };

  // url format: timestamp @ size @ filename.extension
  const splittedURL = url.split("@");
  let fileSize = splittedURL[1];

  let start = url.indexOf("@");
  let result = url.slice(start + 1);
  let extensionIndex = result.lastIndexOf(".");

  let fileName = sanitizePath(
    result.slice(result?.indexOf("@") + 1, extensionIndex) +
      "@" +
      fileSize +
      result.slice(extensionIndex),
    true
  );

  return { fileSize, fileName };
}

/**
 * @param {String} path
 * @param {Boolean} isFile
 * @returns {String} sanitized foldername
 *
 * Todo
 * - Add more regex rules
 */
export const sanitizePath = (path, isFile = false) => {
  if (!path) return "";

  return path
    .replace(isFile ? FILENAME_SANITIZE : FOLDERNAME_SANITIZE, "")
    .trim();
};

export const getHeightAndWidhtOfImage = ({
  windowWidth,
  photoSize,
  padding,
  image,
}) => {
  let imageWidth = windowWidth / 4;
  if (photoSize === 2) {
    imageWidth = windowWidth / 5;
  }
  if (windowWidth < 1024) {
    imageWidth = windowWidth / 2;
  }

  if (padding === 1) {
    imageWidth -= 12;
  } else {
    imageWidth -= 2;
  }

  const ratio = image?.webThumbWidth / image?.webThumbHeight;
  const imageHeight = imageWidth / ratio;

  return {
    imageWidth,
    imageHeight,
  };
};

export const getDataURL = (url, callback) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  var base_image = new Image();
  base_image.crossOrigin = "Anonymous";
  base_image.src = getCFURL({ url });
  base_image.onload = function () {
    canvas.width = base_image.width;
    canvas.height = base_image.height;

    ctx.drawImage(base_image, 0, 0);

    const dataURL = canvas.toDataURL("image/png");
    callback(dataURL);

    canvas.remove();
  };
};

export const detectBrowser = () => {
  let browserName;

  if (navigator.brave) browserName = "brave";
  else browserName = detect(navigator.userAgent)?.name || "other";

  return browserName;
};

export const isDirectorySelectionSupported = () => {
  const browserName = detectBrowser();

  return (
    ["chrome", "edge-chromium", "opera"].includes(browserName) &&
    "showDirectoryPicker" in window
  );
};

/**
 * @param {boolean} isAdmin
 * @param {boolean} allowShare
 * @param {Function} callback
 * @param {"Share"|"Download"} [type="Share"]
 * @param {boolean} isForProductSale
 * @param {boolean} isPurchased
 * @returns {void}
 */
export const allowShareDownload = (
  isAdmin,
  allowShare,
  callback,
  type = "Share",
  isForProductSale = false,
  isPurchased = false
) => {
  if (!isAdmin && !allowShare) {
    toast.error(`${type} is disabled. Contact group admin for access.`);
    return;
  }

  if (isForProductSale && !isPurchased) {
    toast.error(`Buy the image to get ${type.toLowerCase()} access.`);
    return;
  }

  callback && callback();
};

export const getBranchKey = () => {
  return {
    key: ENV.WL_BRANCH_KEY,
    name: ENV.WL_BUSINESS_NAME,
  };
};

/**
 * Function to download image from url,
 * tries to download from cloudfront url first,
 * if it fails, then downloads from digital ocean url
 *
 * @param {String} url
 * @param {Boolean} originalQuality
 * @param {Function} [callback]
 * @param {Function} [progressCallback]
 */
export const handleDownload = (
  _url,
  originalQuality,
  callback,
  progressCallback
) => {
  if (!_url) return;
  callback && callback();
  const url = getCompressedURL(_url);

  const { type, os } = getDeviceType();

  if (type === "desktop" || os === "android")
    return downloadFile(url, originalQuality, progressCallback);

  // try webshare for ios

  const oURL = getCFURL({ url, original: originalQuality });

  webShare(
    oURL,
    oURL,
    () => {
      // if original download fails, try compressed download
      if (originalQuality) {
        const cURL = getCFURL({ url });
        webShare(
          cURL,
          cURL,
          () => {
            downloadFile(url, originalQuality, progressCallback);
          },
          progressCallback
        );
      } else downloadFile(url, originalQuality, progressCallback);
    },
    progressCallback
  );
};

/**
 * @param {String} awsUrl AWS URL of the file in `compressed` directory (non-cloudfront)
 * @param {Boolean} [original=false] Whether to download image from `original` directory
 * @param {Function} [progressCallback] Callback function to update progress
 */
const downloadFile = async (awsUrl, original = false, progressCallback) => {
  if (!awsUrl) return;

  try {
    const originalURL = getCFURL({ url: awsUrl, original });

    const response = await axios.get(originalURL, {
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        if (progressCallback) {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          progressCallback(percent);
        }
      },
    });

    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", getFileName(awsUrl, original));

    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (_) {
    if (original) downloadFile(awsUrl, false, progressCallback);
    else toast.error("Error in downloading");
  }
};

/**
 * @param {string} url
 * @param {boolean} original
 * @returns {string}
 */
const getFileName = (url, original) => {
  if (url.includes("@")) {
    const start = url?.indexOf("@", url?.indexOf("@") + 1);
    const result = url?.slice(start + 1);

    return result;
  } else {
    const val = original ? url?.split("original/") : url?.split("compress/");
    return val[1];
  }
};

/**
 * @param {string} url
 */
export const downloadVideo = (url) => {
  const _url = getCFURL({ url });

  const link = document.createElement("a");
  link.setAttribute("download", getFileName(url, false));
  link.href = _url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};
