import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCFURL } from "utils/helpers";
import { allowShareDownload } from "views/download/helpers";
import Lightbox from "yet-another-react-lightbox";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import LinkShareModal from "views/components/modals/LinkShareModal";
import { modifyAnonymousGallaryRoute } from "utils/helpers/routes";
import { IMAGES_PER_PAGE } from "views/gallary/gallaryPage/components/FolderImages";
import HighResWarn from "views/components/modals/HighResWarn";
import { getImageName } from "utils/helpers";
import useHighResAlert from "hooks/useHighResAlert";
import { isIosDevice } from "hooks/useDetectUserAgent";
import SuspenseLoader from "views/components/loader/SuspenseLoader";
import { pushState } from "utils/helpers/debounced-history";
import webShare from "utils/helpers/web-share";
import { ssProxy } from "utils/helpers/localstorage";
import css from "./index.module.css";

const ProductSalesIcons = React.lazy(() =>
  import("views/gallary/gallaryPage/components/ProductSalesIcons")
);
const CartAddWarn = React.lazy(() => import("./CartAddWarn"));

const AnonymousGalleryModal = ({
  selectedID,
  closeImageView,
  handleDownload,
}) => {
  const [socialShareModal, setSocialShareModal] = useState(false);
  const [cartWarn, setCartWarn] = useState(false);

  const navigate = useNavigate();

  const [params] = useSearchParams();
  const [initialImageId] = useState(selectedID || params.get("imageId"));
  const { images } = useSelector((state) => state.anonymous);
  const folderId = params.get("folderId");
  const page = Number(params.get("page"));
  const { fullScreenImages, totalCount } = useSelector(
    (state) => state.folderImages
  );

  const fGroupCode = ssProxy.getItem("fGroupCode");
  const adminToken = ssProxy.getItem("adminToken");

  const imageIndex = useMemo(() => {
    let idx = 0;
    if (folderId === "my-photos") {
      idx = images?.findIndex((image) => {
        return (
          image?.imageId === initialImageId || image?._id === initialImageId
        );
      });
    } else {
      idx = fullScreenImages?.findIndex((image) => {
        return image?._id === initialImageId;
      });
    }

    return idx >= 0 ? idx : 0;
  }, [initialImageId]);

  const [highResAlert, setHighResAlert] = useState(false);
  const [imgIndex, setImgIndex] = useState(imageIndex);
  const [imgSrcs, setImgSrcs] = useState([]);

  const {
    anonymousUser: { webSettings, group },
  } = useSelector((state) => state.anonymous);

  const allowDownload = webSettings?.download?.allowDownload;

  const { performCheck, setResult } = useHighResAlert(
    params.get("groupId"),
    "anonHighRes"
  );

  useEffect(() => {
    let highRes = false;

    let imgLink =
      folderId === "my-photos"
        ? images?.map((item) => {
            highRes = item?.highRes || highRes;

            return { src: getCFURL({ url: item?.url }) };
          })
        : fullScreenImages?.map((item) => {
            highRes = item?.highRes || highRes;

            return {
              src: getCFURL({ url: item?.url }),
            };
          });

    if (performCheck && isIosDevice()) {
      setHighResAlert(highRes);
      setResult(highRes);
    }
    setImgSrcs(imgLink);
  }, [folderId, fullScreenImages, images]);

  useEffect(() => {
    const newPage = Math.floor(imgIndex / IMAGES_PER_PAGE) + 1;
    let imgId;
    if (folderId === "my-photos") {
      imgId = images[imgIndex]?._id;
    } else if (folderId === "all-photos") {
      imgId = fullScreenImages[imgIndex]?._id;
    } else {
      imgId = fullScreenImages[imgIndex]?.imageId;
    }

    if (newPage !== page) {
      navigate(
        modifyAnonymousGallaryRoute({ imageId: imgId, pageNo: newPage }, params)
      );
    } else {
      pushState(
        null,
        "",
        modifyAnonymousGallaryRoute({ imageId: imgId }, params)
      );
    }
  }, [imgIndex, page]);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return () => document.removeEventListener("contextmenu", handleContextmenu);
  }, []);

  const handleBackbtn = () => {
    let queryString = window.location.search;
    queryString = queryString.split("&imageId=");
    navigate(queryString[0]);
  };

  return (
    <>
      <div className="lightbox">
        {imgSrcs.length > 0 && (
          <Lightbox
            open={open}
            index={imgIndex}
            on={{
              view: ({ index }) => {
                setImgIndex(index);
              },
            }}
            close={closeImageView}
            carousel={{
              preload: 2,
            }}
            slides={imgSrcs}
            slideshow={{ autoplay: false, delay: 3000 }}
            plugins={[Zoom, Slideshow]}
            animation={{ zoom: 300 }}
            toolbar={{
              buttons: [
                <React.Fragment key={folderId}>
                  <div>
                    <img
                      className="navigate_icons gallary-modal-back-button"
                      onClick={() => {
                        handleBackbtn();
                      }}
                      src="/assets/icons/arrow.svg"
                      alt=""
                    />
                  </div>

                  <div className="image_count">
                    {folderId === "my-photos"
                      ? `${imgIndex + 1}/${images.length}`
                      : `${imgIndex + 1}/${totalCount}`}
                  </div>

                  <div className="image_name">
                    {folderId === "my-photos"
                      ? getImageName(images[imgIndex]?.url)
                      : getImageName(fullScreenImages[imgIndex]?.url)}
                  </div>

                  <div className={css["toolbar-container"]}>
                    <ul className={css.toolbar}>
                      {group?.isForProductSale && (
                        <li onClick={() => setCartWarn(true)}>
                          <img
                            src="/assets/icons/add-to-cart.png"
                            title="Add to Cart"
                          />
                        </li>
                      )}

                      <li
                        onClick={() => {
                          const url =
                            folderId === "my-photos"
                              ? images[imgIndex]?.url
                              : fullScreenImages[imgIndex]?.url;

                          handleDownload({ url });
                        }}
                      >
                        <img src="/assets/icons/download.png" alt="Download" />
                      </li>

                      <li
                        onClick={() => {
                          const url =
                            folderId === "my-photos"
                              ? images[imgIndex]?.url
                              : fullScreenImages[imgIndex]?.url;

                          allowShareDownload(
                            false,
                            allowDownload,
                            async () => {
                              await webShare(url, url, () => {
                                setSocialShareModal(getCFURL({ url }));
                              });
                            },
                            "Share",
                            group.isForProductSale,
                            false // anon user can't purchase
                          );
                        }}
                      >
                        <img src="/assets/icons/share.png" alt="Share" />
                      </li>
                    </ul>
                  </div>
                  {socialShareModal && (
                    <LinkShareModal
                      show={socialShareModal}
                      onHide={() => setSocialShareModal("")}
                      title=""
                      url={socialShareModal}
                    />
                  )}

                  <React.Suspense fallback={<SuspenseLoader />}>
                    {group?.isForProductSale && (
                      <>
                        <ProductSalesIcons
                          src="fullsize"
                          uploadedByCurrentUser={false}
                          authCode={false}
                        />

                        {cartWarn && (
                          <CartAddWarn
                            show={true}
                            onCancel={() => setCartWarn(false)}
                            groupCode={group?.groupCode}
                            fGroupCode={fGroupCode}
                            adminToken={adminToken}
                          />
                        )}
                      </>
                    )}
                  </React.Suspense>
                </React.Fragment>,
                isIosDevice() && (
                  <HighResWarn
                    key={Math.random()}
                    show={highResAlert}
                    onHide={() => setHighResAlert(false)}
                  />
                ),
              ],
            }}
            zoom={{
              maxZoomPixelRatio: 5,
              zoomInMultiplier: 2,
              doubleTapDelay: 300,
              doubleClickDelay: 300,
              doubleClickMaxStops: 2,
              keyboardMoveDistance: 50,
              wheelZoomDistanceFactor: 100,
              pinchZoomDistanceFactor: 100,
              scrollToZoom: true,
            }}
          />
        )}
      </div>
    </>
  );
};

export default AnonymousGalleryModal;
