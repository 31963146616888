import {
  GET_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  GET_PURCHASED_ITEMS,
  PURCHASE_ITEMS,
} from "./saga-actions";
import { takeLatest, call, put } from "redux-saga/effects";
import { AXIOS } from "../../utils/setup/axios";
import {
  addItemToCart,
  removeItemFromCart,
  setCart,
  setCartLoader,
  setPurchaseSuccess,
  setPurchasedItems,
} from "redux-store/slices/cart";
import { toast } from "react-toastify";
import {
  BULK_OPERATION_STATUS,
  setBulkOperationStatus,
} from "redux-store/slices/globals";

function getCart(data) {
  return AXIOS.get("/api/premium/get-product-cart", { params: data });
}

function* getCartSaga(action) {
  try {
    yield put(setCartLoader(true));
    const response = yield call(getCart, action.payload);
    yield put(setCart(response.data?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(setCartLoader(false));
  }
}

function addToCart(data) {
  return AXIOS.post("/api/premium/add-products-to-cart", data);
}

function* addToCartSaga(action) {
  try {
    const { bulk, ...payload } = action.payload;
    if (bulk) yield put(setCartLoader(true));

    const response = yield call(addToCart, payload);
    yield put(addItemToCart(response.data?.data));

    if (bulk) {
      toast.success(response.data?.message);
      yield put(setBulkOperationStatus(BULK_OPERATION_STATUS.SUCCESS));
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    if (action.payload.bulk) {
      yield put(setBulkOperationStatus(BULK_OPERATION_STATUS.ERROR));
    }
  } finally {
    yield put(setCartLoader(false));
  }
}

function removeFromCart(data) {
  return AXIOS.post("/api/premium/remove-products-from-cart", data);
}

function* removeFromCartSaga(action) {
  try {
    const { bulk, ...payload } = action.payload;
    if (bulk) yield put(setCartLoader(true));

    const response = yield call(removeFromCart, payload);
    yield put(removeItemFromCart(response.data.data?.removedCartItems));

    if (bulk) {
      toast.success(response.data?.message);
      yield put(setBulkOperationStatus(BULK_OPERATION_STATUS.SUCCESS));
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    if (action.payload.bulk) {
      yield put(setBulkOperationStatus(BULK_OPERATION_STATUS.ERROR));
    }
  } finally {
    yield put(setCartLoader(false));
  }
}

function getPurchasedItems(data) {
  const { id } = data;
  return AXIOS.get(`/api/app/pic/paid-pics/${id}`);
}

function* getPurchasedItemsSaga(action) {
  try {
    const response = yield call(getPurchasedItems, action.payload);
    yield put(setPurchasedItems(response.data?.data?.newProductSales));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(setCartLoader(false));
  }
}

function purchaseCartItems(data) {
  return AXIOS.post("/api/app/user/purchase-products-using-wallet", data);
}

function* purchaseCartItemsSaga(action) {
  try {
    yield put(setCartLoader(true));
    const response = yield call(purchaseCartItems, action.payload);
    yield put(setPurchaseSuccess(response.data?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
    yield put(setCartLoader(false));
  }
}

export function* cartSaga() {
  yield takeLatest(GET_CART, getCartSaga);
  yield takeLatest(ADD_TO_CART, addToCartSaga);
  yield takeLatest(REMOVE_FROM_CART, removeFromCartSaga);
  yield takeLatest(GET_PURCHASED_ITEMS, getPurchasedItemsSaga);
  yield takeLatest(PURCHASE_ITEMS, purchaseCartItemsSaga);
}
