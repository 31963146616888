import ENV from "./env";
import { BRANCH_LINK_REPLACE } from "constants/regexlib";

/**
 * @param {string} link
 * @returns {string}
 */
const replaceBranchLink = (link) => {
  if (!BRANCH_LINK_REPLACE.test(link)) return link;

  const [, subdomain, path] = link.match(BRANCH_LINK_REPLACE);
  if (!subdomain || !path) return link;

  if (subdomain === "kwikpic-in") return link;
  return `https://${subdomain}.kwik.pics${path}`;
};

/**
 * Share message for public group with full access
 * @param {string} name
 * @param {string} groupName
 * @param {string} branchLinkApp
 * @param {string} branchLinkWeb
 * @param {string} adminToken
 * @param {string} groupCode
 * @param {string} fGroupCode
 * @param {string} [paramsToAppend]
 * @returns {string}
 */
export const pubGFA = (
  name,
  groupName,
  branchLinkApp,
  branchLinkWeb,
  adminToken,
  groupCode,
  fGroupCode,
  paramsToAppend = ""
) => {
  return `${name} is inviting you to join “${groupName}”.
Get your own photos instantly using Face Recognition!
${
  ENV.WL_WEB_SHARE_ONLY
    ? ""
    : `
Join via Mobile App - Full Access (Recommended)
${replaceBranchLink(branchLinkApp)}&adminToken=${adminToken}${paramsToAppend}
`
}
Join via Website - Full Access
${replaceBranchLink(branchLinkWeb)}?uCode=${
    fGroupCode || `${groupCode}&adminToken=${adminToken}`
  }${paramsToAppend}

Join via Ucode
${fGroupCode}
`;
};

/**
 * Share message for public group with partial access and private groups
 * @param {string} name
 * @param {string} groupName
 * @param {string} branchLinkApp
 * @param {string} branchLinkWeb
 * @param {string} groupCode
 * @param {string} [paramsToAppend]
 * @returns {string}
 */
export const privGPA = (
  name,
  groupName,
  branchLinkApp,
  branchLinkWeb,
  groupCode,
  paramsToAppend = ""
) => {
  return `${name} is inviting you to join “${groupName}”.
Get your own photos instantly using Face Recognition!
${
  ENV.WL_WEB_SHARE_ONLY
    ? ""
    : `   
Join via Mobile App (Recommended)
${replaceBranchLink(branchLinkApp)}${paramsToAppend}
`
}
Join via Website
${replaceBranchLink(branchLinkWeb)}?uCode=${groupCode}${paramsToAppend}

Join via Ucode
${groupCode}
`;
};
